<template>
    
</template>


<script>
import API from '@/services/api';

export default {
    name: "editBoundaryOrPOI",
    components: {
        
    },
    created(){

    },
    data(){
        return{

        }
    },
    mounted(){

    },
    computed:{

    },
    methods:{

    },
    

}
</script>


<style scoped>

</style>