<!-- TabsComponent.vue (Child Component) -->
<template>
  <div>
    <div
      class="tabs-container"
      :style="tabsContainerStyles()"
      style="position: relative"
    >
      <div
        v-for="(tab, index) in tabsData.options"
        :key="index"
        @click="setActiveTab(index)"
        :class="{ active: activeTab === index }"
        :style="getStyles()"
      >
        {{ tab.tab_name }}
      </div>
    </div>
    <div>
      <DynamicDataComponent
        :data="tabsData.options[activeTab].tab_fields"
        :type="type"
        @offGridActive="offGridActive"
        @generateData="generateData"
      />
    </div>
  </div>
</template>

<script setup>
import DynamicDataComponent from "./DynamicDataComponent.vue";
import { solarCalculatorStore } from "../../../../store/solarCalculator";
import { onMounted, ref, watch } from "vue";

const props = defineProps(["tabsData", "type", "screen", "fetching_error"]);
const emits = defineEmits([
  "scrollToTop",
  "generateResults",
  "handleErrorResponse",
]);
const store = solarCalculatorStore();
const calculatorData = store.CALCULATOR_DATA_STATE;
let activeTab = ref(calculatorData.active_tab);

const generateData = () => {
  emits("generateResults");
};

watch(
  () => props.fetching_error,
  (newVal, oldVal) => {
    if (newVal === true) {
      console.log("fetching_error changed from", oldVal, "to", newVal);
      activeTab.value = calculatorData.active_tab;
      emits("handleErrorResponse");
    }
  }
);

const setActiveTab = (index) => {
  activeTab.value = index;
  if (activeTab.value === 0) {
    store.UPDATE_CALCULATOR_DATA("On-Grid", "SelectedGrid");
    store.UPDATE_CALCULATOR_DATA(0, "active_tab");
    emits("generateResults");
  }
  if (activeTab.value === 1) {
    store.UPDATE_CALCULATOR_DATA("Off-Grid", "SelectedGrid");
    store.UPDATE_CALCULATOR_DATA(1, "active_tab");
    emits("generateResults");
  }
};

const offGridActive = () => {
  setActiveTab(1);
};

const getStyles = () => {
  if (props.screen === "full") {
    return {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",

      padding: "15px",

      cursor: "pointer",
    };
  } else {
    return {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",

      padding: "15px",
      margin: "5px",
      cursor: "pointer",
    };
  }
};

const tabsContainerStyles = () => {
  if (props.screen === "full") {
    return {
      display: "flex",
      color: "black",
      backgroundColor: "white",
      boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "8px",
    };
  } else {
    return {
      display: "flex",
      color: "#777777",
      backgroundColor: "#E8EDF2",
      fontFamily: "Arial",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
      borderRadius: "8px",
    };
  }
};
</script>

<style scoped>
.active {
  background-color: #006eaf;
  color: white;
}
</style>
