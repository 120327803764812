<template>
    <div >
        <el-carousel height="90vh"  :interval="1500" class="hide-arrows">
    <el-carousel-item v-for="item in carsoualData" :key="item">
      <img  :src="item" alt="" style="width:100%;height:100%">
    </el-carousel-item>
  </el-carousel>
    </div>
</template>

<script>
import img1 from '../../assets/carsoulimgs/image1.jpg';
import img2 from '../../assets/carsoulimgs/image2.jpg';
import img3 from '../../assets/carsoulimgs/image3.jpg';
import { ref } from 'vue';
export default {
    setup () {  
        const carsoualData= ref([img1,img2,img3]);
        return {carsoualData}
    }
}
</script>

<style scoped>
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

</style>