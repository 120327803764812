<template>
  <div class="container">
    <div class="heading" v-if="showHeading && cardData.label !== 'Title'">
      <p style="margin-bottom: 10px">{{ cardData.label }}</p>
    </div>
    <div class="cardContainer">
      <div :class="getStyles(cardData)" v-for="item in cardData.options">
        <div class="left">
          <img
            :src="selectedImages(item.additional_info.prepend_image)"
            alt="image"
            class="imgClass"
            v-if="item?.additional_info?.prepend_image"
          />
        </div>
        <div
          class="right"
          style="display: flex; align-items: center; flex-direction: row"
        >
          <div
            style="
              display: flex;

              flex-direction: column;
              gap: 10px;
            "
          >
            <p class="inner_heading">{{ item.label }}</p>
            <p
              class="inner_content"
              v-if="item.question_type !== 'input_info_card'"
            >
              {{ item.description }}
            </p>
            <div
              v-if="item.question_type === 'input_info_card'"
              class="number_input"
            >
              <div
                :class="handleDecreaseStyle('decrease')"
                style="margin-right: 10px"
                @click="handleTime(panelNumber, 'decrease', item.label)"
              >
                -
              </div>
              <div class="time_input">
                <input
                  type="number"
                  v-model="panelNumber"
                  class="input_time"
                  @input="handleTimeInput(panelNumber, item.label)"
                />
                <p style="margin-left: auto" class="unit_text">kW</p>
              </div>
              <div class="time_button" style="margin-left: 10px">
                <p
                  style="margin-bottom: 2px"
                  @click="handleTime(panelNumber, 'increase', item.label)"
                >
                  +
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-for="items in cardData.options" class="note_container">
    <div v-if="items.additional_info.note">
      <span class="note">{{ items.additional_info.note }}</span>
    </div>
  </div>
  <p class="infoHolder" v-if="showInfoHolder">
    {{ infoHolderText }}
  </p>
  <div class="info_container">
    <el-dialog v-model="showInformation" width="45%" title="Information">
      <div v-for="item in cardData.options">
        <div v-if="item.sub_options.length > 0" style="display: flex">
          <div v-for="items in item.sub_options">
            <table>
              <tr>
                <td class="label">Brand</td>
                <td class="label_data">
                  <p v-if="items.options_details[0].brand">
                    {{ items.options_details[0].brand }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Model</td>
                <td class="label_data">
                  <p v-if="items.options_details[0].model">
                    {{ items.options_details[0].model }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Cell Technology</td>
                <td class="label_data">-</td>
              </tr>
              <tr>
                <td class="label">System Voltage</td>
                <td class="label_data">-</td>
              </tr>
              <tr>
                <td class="label">Type of Solar Panel</td>
                <td class="label_data">
                  <p v-if="items.options_details[0].type">
                    {{ items.options_details[0].type }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Nominal Power (W)</td>
                <td class="label_data">
                  <p
                    v-if="
                      items.options_details[0].moduleProperties.nominal_power
                    "
                  >
                    {{
                      items.options_details[0].moduleProperties.nominal_power
                    }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Panel Efficiency</td>
                <td class="label_data">
                  <p
                    v-if="items.options_details[0].moduleProperties.efficiency"
                  >
                    {{ items.options_details[0].moduleProperties.efficiency }}
                    %
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Cell-type</td>
                <td class="label_data">
                  <p v-if="items.options_details[0].moduleProperties.cell_type">
                    {{ items.options_details[0].moduleProperties.cell_type }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">No of Cells</td>
                <td class="label_data">
                  <p
                    v-if="items.options_details[0].moduleProperties.cell_number"
                  >
                    {{ items.options_details[0].moduleProperties.cell_number }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Vmpp (Nominal Power Voltage)</td>
                <td class="label_data">
                  <p v-if="items.options_details[0].moduleProperties.vmpp">
                    {{ items.options_details[0].moduleProperties.vmpp }}V
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Open Circuit Voltage (Voc), V</td>
                <td class="label_data">
                  <p v-if="items.options_details[0].moduleProperties.voc">
                    {{ items.options_details[0].moduleProperties.voc }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Product Warranty</td>
                <td class="label_data">
                  <p v-if="items.options_details[0].product_warranty">
                    {{ items.options_details[0].product_warranty }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Power Warranty</td>
                <td class="label_data">
                  <p v-if="items.options_details[0].power_warranty">
                    {{ items.options_details[0].power_warranty }}
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
              <tr>
                <td class="label">Description</td>
                <td class="label_data">-</td>
              </tr>
              <tr>
                <td class="label">Dimensions (L*W*H)</td>
                <td class="label_data">
                  {{
                    `${items.options_details[0].moduleProperties.length}mm * ${items.options_details[0].moduleProperties.width}mm * ${items.options_details[0].moduleProperties.height}mm`
                  }}
                </td>
              </tr>
              <tr>
                <td class="label">Area</td>
                <td class="label_data">-</td>
              </tr>
            </table>

            <!-- {{ items.options_details }} -->
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref } from "vue";

import MoneySave from "../../assets/MoneySave.svg";
import { solarCalculatorStore } from "../../../store/solarCalculator";
import Returns from "../../assets/Returns.svg";
import Wallet from "../../assets/Wallet.svg";
import Edit from "element-plus";
import Carbon from "../../assets/co2.svg";
import Battery from "../../assets/bigBattery.svg";
import tree from "../../assets/Plant.svg";
import coalBurnt from "../../assets/coalBurnt.svg";
import systemSize from "../../assets/systemSize.svg";
import Invertor from "../../assets/Invertor.svg";
import areaRequired from "../../assets/areaRequired.svg";

const store = solarCalculatorStore();
const showInformation = ref(false);
const solarCalculatorData = store.calculatorData;
const system_size = solarCalculatorData.system_size;
const panelNumber = ref(system_size);
const systemSizeImage = ref(systemSize);
const moneySaveImage = ref(MoneySave);
const batteryImage = ref(Battery);
const walletImage = ref(Wallet);
const returnsImage = ref(Returns);
const invertorImage = ref(Invertor);
const carbonImage = ref(Carbon);
const treeImage = ref(tree);
const coalBurnImage = ref(coalBurnt);
const areaImage = ref(areaRequired);

const emits = defineEmits(["updateCalculatorsData"]);

const props = defineProps({
  cardData: {
    type: Array,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
  showInfoHolder: {
    type: Boolean,
    default: false,
  },
  showHeading: {
    type: Boolean,
    default: true,
  },

  infoHolderText: {
    type: String,
  },

  info_style: {
    type: String,
  },
});

const handleTimeInput = (data, label) => {
  store.UPDATE_CALCULATOR_DATA(data, label);
  store.UPDATE_CALCULATOR_DATA(data, "dc_size");
  handleData();
};

const handleData = () => {
  emits("updateCalculatorsData");
};

const handleTime = (data, method, label) => {
  console.log(label);
  switch (method) {
    case "decrease":
      if (panelNumber.value > 1) {
        panelNumber.value -= 1;
        handleData();
      }
      store.UPDATE_CALCULATOR_DATA(panelNumber.value, label);
      store.UPDATE_CALCULATOR_DATA(panelNumber.value, "dc_size");

      break;
    case "increase":
      panelNumber.value += 1;
      store.UPDATE_CALCULATOR_DATA(panelNumber.value, label);
      store.UPDATE_CALCULATOR_DATA(panelNumber.value, "dc_size");
      handleData();
    default:
      break;
  }
};

const selectedImages = (data) => {
  switch (data) {
    case "systemSizeImage":
      return systemSizeImage.value;
      break;

    case "batteryImage":
      return batteryImage.value;
      break;
    case "moneySaveImage":
      return moneySaveImage.value;
      break;
    case "walletImage":
      return walletImage.value;
      break;
    case "returnsImage":
      return returnsImage.value;
      break;
    case "invertorImage":
      return invertorImage.value;
      break;
    case "carbonImage":
      return carbonImage.value;
      break;
    case "treeImage":
      return treeImage.value;
      break;
    case "coalBurnImage":
      return coalBurnImage.value;
      break;
    case "areaImage":
      return areaImage.value;
      break;
    default:
      return null;
      break;
  }
};
const showInformationData = () => {
  showInformation.value = true;
};

const handleDecreaseStyle = (trigger) => {
  if (trigger === "decrease" && panelNumber.value === 1) {
    return "time_button_disabled";
  }

  return "time_button";
};

const handleCalculatorData = (data, label) => {
  store.UPDATE_CALCULATOR_DATA(data, label);
};

const getStyles = (data) => {
  if (data.additional_data.card_type) {
    if (data.additional_data.card_type === "width_card") {
      return "widthCard";
    } else {
      return "infoCard";
    }
  } else {
    return "infoCard";
  }
};
</script>

<style scoped>
.heading {
  color: var(--777, #777);
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 2px solid var(--CCC, #ccc);
  margin-top: 10px;
  margin-bottom: 5px;
}
.infoCard {
  display: flex;
  flex: 1;
  /* width: 49%; */
  /* height: 18vh; */
  padding: 18px 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--CCC, #ccc);
  margin-top: 10px;
}

.widthCard {
  display: flex;
  /* flex: 1; */
  width: 49%;
  /* height: 18vh; */
  padding: 10px 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--CCC, #ccc);
  margin-top: 10px;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
}

.inner_heading {
  color: var(--777, #777);
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}

.inner_content {
  color: var(--grey-grey-5, #0d0d0d);
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.note {
  color: var(--Grey-777, var(--777, #777));
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.infoHolder {
  color: var(--arka-text, #141414);
  font-family: Switzer;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cardContainer {
  display: flex;
  align-items: start;
  gap: 10px;
  flex-wrap: wrap;
}

.number_input >>> .el-input-number__decrease {
  margin-right: 10px;
  border-radius: 25px;
  background-color: #0d0d0d;
  color: white;
  font-size: 20px;

  font-weight: 700;
  height: 30px;
}

.number_input >>> .el-input-number__increase {
  border-radius: 25px;
  background-color: #0d0d0d;
  color: white;
  height: 30px;
  font-size: 20px;
  font-weight: 700;
}

.number_input >>> .el-input__inner {
  border: 1px;
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color))
    inset;
}

.number_input >>> .el-input__inner ::after {
  content: "panels";
  display: block;
}

.container {
  margin-bottom: 8px;
}

.i_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  background-color: #777;
  border-radius: 50%;
  margin-left: 5px;

  cursor: pointer;
}

.i_content {
  font-size: 12px;
  font-weight: 800;
  font-family: cursive;
  color: white;
}

.note_container {
  color: var(--Grey-777, var(--777, #777));
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.number_input >>> .el-input__wrapper {
  box-shadow: none;
}

.number_input {
  display: flex;
  align-items: center;
}

table {
  width: 100%;
  color: #0d0d0d;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.label {
  width: 50%;
  color: #000;

  font-family: Switzer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.label_data {
  width: 500px;
  color: #000;

  font-family: Switzer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.info_container >>> .el-dialog__header {
  background-color: #e8edf2;
  margin: 0;
  border-radius: 8px;
}

.info_container >>> .el-dialog {
  border-radius: 8px;
}

.time_button {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  color: white;
  background-color: #0d0d0d;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.time_button_disabled {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  color: white;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}

.time_input {
  border-radius: 4px;
  border: 1px solid var(--999, #999);
  background: var(--White, #fff);
  width: 60px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.input_time {
  outline: none;
  border: none;
  width: 20px;
}

.unit_text {
  color: var(--Grey-777, var(--777, #777));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.time_input >>> input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.info_container >>> .el-dialog__body {
  padding-top: 5px;
}

@media screen and (max-width: 1180px) and (min-width: 360px) {
  .cardContainer {
    flex-direction: column;
    width: 100%;
  }

  .infoCard {
    width: 100%;
    padding: 5px 16px;
    gap: 30px;
  }

  .widthCard {
    width: 100%;
    gap: 30px;
  }
}
</style>
