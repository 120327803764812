<template>
  <div>
    <div class="header">
      <p style="margin-bottom: 10px">Our Financing Partners</p>
    </div>
    <div class="logo-container">
      <div class="logos-wrapper">
        <img
          v-for="(logo, index) in logos"
          :key="index"
          :src="logo"
          :alt="'logo-' + index"
          class="logo"
        />
      </div>
      <div class="logos-wrapper">
        <img
          v-for="(logo, index) in logos"
          :key="index"
          :src="logo"
          :alt="'logo-' + index"
          class="logo"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import econfy from "../../assets/ecofy.png";
import axis from "../../assets/axis.png";
import greenlance from "../../assets/greenlance.png";
import hdfc from "../../assets/hdfc.png";
import icici from "../../assets/icici.png";

const logos = ref([
  "https://www.ecofy.co.in/themes/cleantech/images/logo-ecofy-sticky.png",
  axis,
  "https://www.greenlance.energy/assets/img/logo/logo.png",
  hdfc,
  "https://static.vecteezy.com/system/resources/previews/020/190/435/original/icici-logo-icici-icon-free-free-vector.jpg",
  "https://creditfair.in/assets/front/img/rebrandedLogo/orange3.png",
  "https://bank.sbi/o/SBI-Theme/images/custom/logo.png",
  "https://upload.wikimedia.org/wikipedia/en/thumb/f/f2/BankOfBarodaLogo.svg/1200px-BankOfBarodaLogo.svg.png",
  "https://bfsi.eletsonline.com/wp-content/uploads/2022/09/Union-Bank-of-India-new.jpg",
  "https://www.sidbi.in/assets/front/images/enhi.jpg",
  "https://www.yesbank.in/static_cache/img/new-logo.png",
  "https://upload.wikimedia.org/wikipedia/commons/d/d8/Tata_Capital_Logo-01.jpg",
  "https://cms.jeevanutsav.com/wp-content/uploads/2022/08/tata-cleantech.jpg",
]);
</script>

<style scoped>
.header {
  color: var(--777, #777);
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 2px solid var(--CCC, #ccc);
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo-container {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  padding: 23px;
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color))
    inset;
  border-radius: 8px;
}

.logos-wrapper {
  display: inline-block;
  animation: 20s slide linear infinite; /* Adjust the duration as needed */
  padding: 10px 12px;
}

.logos-wrapper >>> img {
  height: 50px;
  width: auto;
}

.logo {
  margin-right: 20px; /* Adjust the margin between logos as needed */
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
