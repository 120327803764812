import { createApp } from 'vue';
import './style.css'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { ElMessage } from 'element-plus'
import { ElLoading } from 'element-plus'
import { GOOGLE_API_KEY } from "@/services/api/index.js";
import { createPinia } from 'pinia';
import { CAMERA_TYPE_2D } from './core/utilities/constants';
import VueTelInput from 'vue-tel-input';
import './../node_modules/vue-tel-input/dist/vue-tel-input.css';
// import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-bootstrap.css';

let isSentryInitialized = false;

export default class Main {
    constructor() {
        let sentryKey = 'https://2f209474c1b1dc9c449a35196393dc00@o4507464086650880.ingest.us.sentry.io/4507542232236032';
        let environment = import.meta.env.VITE_APP_ENVIRONMENT;
        this.app = createApp(App);
        if (sentryKey && !isSentryInitialized) {
            Sentry.init({
                app: this.app,
                dsn: sentryKey,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration(),
                ],
                environment: environment,
                // debug: true,
                // Performance Monitoring
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    /^https:\/\/devgosolar\.arka360\.com\//,
                    /^https:\/\/betagosolar\.arka360\.com\//,
                    /^https:\/\/gosolar\.arka360\.com\//,
                ],                
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
            isSentryInitialized = true;
        }
        this.app.use(router);
        this.app.use(ElLoading);
        this.app.use(ElMessage);
        this.app.use(createPinia());
        this.app.use(VueTelInput);
        // this.app.use(ToastPlugin);
        this.app.use(VueGoogleMaps, {
            load: {
                key: GOOGLE_API_KEY,
                libraries: 'places', // required if using the Autocomplete plugin
            },
        }).mount('#app');

    }
    main() {
        console.log('Hey');
    }
}

export const main = new Main();


